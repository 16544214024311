<template>
  <div class="view-container">
    <div class="card">
      <div class="card-header card-header-divider">
        <div class="card-title">Настройки уведомлений</div>
      </div>
      <div class="card-body">
        <notifications-options-form/>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationsOptionsForm from '../../components/Forms/Notifications/NotificationsOptionsForm.vue';
export default {
  components: { NotificationsOptionsForm },
  name: "notifications-options",
};
</script>

<style>
</style>