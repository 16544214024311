<template>
  <form
    v-if="optionsList"
    action="#"
    @submit.prevent="submitNotificationOptions"
    novalidate
    class="notif-options"
  >
    <div class="flex flex-column m-auto notif-options__checkboxes">
      <custom-checkbox
        v-for="(value, option) in optionsList"
        :key="option"
        v-model="form[option]"
        :checked="value"
        :label="optionsLabels[option]"
      />
    </div>
    <div class="mt-25 mb-10 display--flex justify-content-end">
      <button type="submit" class="btn btn-accent mr-10">Сохранить</button>
      <button type="button" @click="router.go(-1)" class="btn btn-w">
        Назад
      </button>
    </div>
    <div class="form-preloader" v-if="isPreloader"><preloader /></div>
  </form>
  <div v-else class="table-preloader"><preloader /></div>
</template>

<script>
import { useStore } from "vuex";
import { reactive, ref } from "@vue/reactivity";

import { computed, onBeforeMount } from "@vue/runtime-core";
import Preloader from "../../Technical/Preloader.vue";
import useErrors from "../../../mixins/useErrors";
import standartError from "../../../mixins/standartError";
import CustomCheckbox from "../Fields/CustomCheckbox.vue";
import { useRouter } from 'vue-router';

export default {
  components: { Preloader, CustomCheckbox },
  name: "notifications-options-form",
  setup() {
    const store = useStore(), router = useRouter(),
      { setErrors, clearCustomErrors } = useErrors();
    const profile = computed(() => store.state.profile.profile),
      optionsLabels = computed(() => store.state.notifications.optionsLabels),
      optionsList = computed(() => store.state.notifications.optionsList);
    const form = reactive({}),
      isPreloader = ref(false);

    const submitNotificationOptions = () => {
      isPreloader.value = true;
      store
        .dispatch("notifications/saveOptionsNotification", form)
        .then(() => (isPreloader.value = false))
        .catch(standartError(setErrors, clearCustomErrors, isPreloader));
    };

    onBeforeMount(async () => {
      await store.dispatch("notifications/getOptionsLabels");
      await store.dispatch("notifications/getOptionsList")
      for(let option in optionsList.value) form[option] = optionsList.value[option]
    });

    return {
      form,
      router,
      profile,
      submitNotificationOptions,
      isPreloader,
      optionsLabels,
      optionsList,
    };
  },
};
</script>

<style lang="scss" scoped>
.notif-options{
  margin-top: 15px;
  &__checkboxes{
    .form-group{
      margin-bottom: 5px;
    }
    @media(min-width: 1024px){
      width: max-content;
    }
  }
}
</style>